import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b031d4b = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _1d5695d0 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _5e16f8c2 = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _c5bbab7e = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _9149e7e0 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _28cc573c = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _699804b4 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _054fba9e = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _68f28845 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _16165271 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _3e2f6f12 = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _59c6aaad = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _63171a61 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _73e736ee = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _3c82786f = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _7f0c4c54 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _5f24b425 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _4b031d4b,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _1d5695d0,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _5e16f8c2,
    name: "callback"
  }, {
    path: "/downloads",
    component: _c5bbab7e,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _9149e7e0,
    name: "guests-details"
  }, {
    path: "/login",
    component: _28cc573c,
    name: "login"
  }, {
    path: "/maintenance",
    component: _699804b4,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _054fba9e,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _68f28845,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _16165271,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _3e2f6f12,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _59c6aaad,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _63171a61,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _73e736ee,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _3c82786f,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _7f0c4c54,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _5f24b425,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
